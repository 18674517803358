import React from 'react';
import _ from 'lodash';

import {Layout} from '../components/index';
import {htmlToReact, safePrefix} from '../utils';

export default class Contact extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
            <div className="outer">
              <div className="inner">
                <div className="block-item">
                  <div className="grid">
                    <div className="cell block-preview">
                      <form name="contactForm" method="POST" netlify-honeypot="bot-field" data-netlify="true" id="contact-form"
                        className="contact-form">
                        <p className="screen-reader-text">
                          <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <p className="form-row">
                          <label className="form-label">Name</label>
                          <input type="text" name="name" className="form-input"/>
                        </p>
                        <p className="form-row">
                          <label className="form-label">Email address</label>
                          <input type="email" name="email" className="form-input"/>
                        </p>
                        <p className="form-row">
                          <label className="form-label">Message</label>
                          <textarea name="message" className="form-textarea" rows="7" />
                        </p>
                        <input type="hidden" name="form-name" value="contactForm" />
                        <p className="form-row form-submit">
                          <button type="submit" className="button">Send Message</button>
                        </p>
                      </form>
                    </div>

                    <div className="cell block-content">
                      <h3 className="block-title underline">{this.props.pageContext.frontmatter.title}</h3>
                      <div className="block-copy">
                      {this.props.pageContext.frontmatter.subtitle && 
                        <div className="post-subtitle">
                          {htmlToReact(this.props.pageContext.frontmatter.subtitle)}
                        </div>
                        }
                        {this.props.pageContext.frontmatter.img_path && 
                        <div className="post-thumbnail">
                          <img src={safePrefix(this.props.pageContext.frontmatter.img_path)} alt={this.props.pageContext.frontmatter.title} />
                        </div>
                        }

                        {htmlToReact(_.get(this.props, 'pageContext.html'))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Layout>
        );
    }
}
